import * as TYPES from './action-types';
import * as storage from '../../utils/storage';
import { readExitIntelEmail } from '../../utils/readCookie';

const INITIAL_STATE = {
	initalizing: false,
	initialized: false,
	error: false,
	fullName: '',
	street: '',
	city: '',
	state: '',
	postalCode: '',
	phone: '',
	country: 'US',
	email: storage.get(storage.names.email),
	klaviyoEmail: readExitIntelEmail('exitintel_email'),
	remarketingUrl: '',
	isRemarketing: false,
	shippingInfoError: JSON.parse(localStorage.getItem('shippingInfoError')),
	ip: "",
	browser: '',
};

// ----- INITALIZE -----

const initializeStart = state => ({
	...state,
	initalizing: true,
	error: null,
});

const initializeEnd = (state, { email, user }) => ({
	...state,
	...user,
	email: email || user?.email || user?.klaviyoEmail || readExitIntelEmail('exitintel_email'),
	initalizing: false,
	initialized: true,
	error: null,
	klaviyoEmail: user?.klaviyoEmail || email || user?.email,
	street: user?.street ? user.street : user?.address,
	postalCode: user?.postalCode ? user.postalCode : user?.postCode,
	isRemarketing: false,
});

const updateShippingInfo = (
	oldState,
	{ fullName, street, city, state, postalCode, phone, country, shippingInfoError, email },
) => ({
	...oldState,
	fullName,
	street,
	city,
	state,
	postalCode,
	phone,
	country,
	shippingInfoError,
	email
});

const updateEmail = (state, email) => ({
	...state,
	email,
	klaviyoEmail: !state.klaviyoEmail ? email : '',
});

const initializeKlaviyoStart = state => ({
	...state,
	error: null,
});

const initializKlaviyoError = (state, error) => ({
	...state,
	error,
});

const initializeKlaviyoEnd = (state, payload) => ({
	...state,
	email: !state.email ? payload.email || state.klaviyoEmail : state.email,
	klaviyoEmail: payload.email ? payload.email : state.klaviyoEmail || state.email,
});

const loadSessionEnd = (state, { address, phone, fullName, email }) => ({
	...state,
	...address,
	phone,
	email,
	klaviyoEmail: email,
	fullName,
	shippingInfoError: true,
	isRemarketing: true,
});

const saveUserDetails = (state, payload) => ({
	...state,
	...payload,
	shippingInfoError: payload ? false : true,
})

export const userReducer = (state = INITIAL_STATE, { type, payload }) => {
	switch (type) {
		case TYPES.INITIALIZE_USER_START:
			return initializeStart(state);
		case TYPES.INITIALIZE_USER_END:
			return initializeEnd(state, payload);
		case TYPES.USER_UPDATE_SHIPPING_INFO:
			return updateShippingInfo(state, payload);
		case TYPES.USER_UPDATE_EMAIL:
			return updateEmail(state, payload);
		case TYPES.INITIALIZE_KLAVIYO_USER_START:
			return initializeKlaviyoStart(state);
		case TYPES.INITIALIZE_KLAVIYO_USER_ERROR:
			return initializKlaviyoError(state, payload);
		case TYPES.INITIALIZE_KLAVIYO_USER_END:
			return initializeKlaviyoEnd(state, payload);
		case TYPES.LOAD_SESSION_END:
			return loadSessionEnd(state, payload);
		case TYPES.LOAD_SESSION_ERROR:
			return {
				...state,
				shippingInfoError: true,
			};
		case TYPES.SAVE_USER_DETAILS:
			return saveUserDetails(state, payload)
		default:
			return state;
	}
};
