export const INITIALIZE_USER_START = 'INITIALIZE_USER_START';
export const INITIALIZE_USER_END = 'INITIALIZE_USER_END';

export const LOAD_SESSION_START = 'LOAD_SESSION_START';
export const LOAD_SESSION_END = 'LOAD_SESSION_END';
export const LOAD_SESSION_ERROR = 'LOAD_SESSION_ERROR';

export const INITIALIZE_KLAVIYO_USER_START = 'INITIALIZE_KLAVIYO_USER_START';
export const INITIALIZE_KLAVIYO_USER_ERROR = 'INITIALIZE_KLAVIYO_USER_ERROR';
export const INITIALIZE_KLAVIYO_USER_END = 'INITIALIZE_KLAVIYO_USER_END';

export const USER_UPDATE_SHIPPING_INFO = 'USER_UPDATE_SHIPPING_INFO';
export const USER_UPDATE_EMAIL = 'USER_UPDATE_EMAIL';

export const SAVE_SESSION_END =  'SAVE_SESSION_END';
export const SAVE_USER_DETAILS = 'SAVE_USER_DETAILS';
