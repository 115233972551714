import React, { useEffect } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { FakeBtn } from './CheckoutComponents';
import { checkoutActions } from '../../../redux/checkout';
import { a_payPalButtonPressed, a_upsellShown } from '../../../api/analytics';
import { isMobile } from '../../../utils/dimensions';
import './paypal.scss';
import { toggleModal } from '../../../redux/app/actions';
import { nanoid } from 'nanoid';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { verifyAddress } from '../shipping-info/ShippingInfo';

import { PaypalButtonLogo } from '../../../assets/images/PaypalButtonLogo';
import { updateCheckout } from '../../../redux/checkout/actions';

const { updatePaypalInfo, simulateCheckout } = checkoutActions;

const PayPalBtn = ({ btnType }) => {
  const { paypalCheckout, cancelPaypalCheckout } = checkoutActions;
  const { paypal, fakePaypal } = btnType;
  const dispatch = useDispatch();
  const { app, user, checkout, boards } = useSelector((state) => state);
  const { shippingCost, totalPrice, totalWithSubscription } = checkout;
  const currency = useSelector((state) => state.coupon.currency) || 'USD';
  const { subscriptions } = app;

  const { oneTimeOrSubscription } = checkout;
  const [activeSubscription] = subscriptions.filter(
    (subscription) => subscription.isDefault,
  );

  return (
    <div
      className={classNames({
        'paypalbuttons-hidden': !checkout.isCheckoutOpen
          ? true
          : !paypal && !fakePaypal,
        'paypalbuttons-fake': fakePaypal,
        'paypalbuttons-real': paypal,
      })}
    >
      <FakeBtn user={user} minBoards={app.minBoards} type="paypal" />
      <PayPalButtons
        forceReRender={[
          user,
          totalPrice,
          shippingCost,
          oneTimeOrSubscription,
          totalWithSubscription,
        ]}
        style={{
          height: 52,
          tagline: false,
          layout: 'horizontal',
        }}
        createOrder={
          oneTimeOrSubscription === 'one-time'
            ? (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: (totalPrice + shippingCost).toFixed(2),
                    },
                    description: 'Photo Boards',
                  },
                ],
              });
            }
            : undefined
        }
        createSubscription={
          oneTimeOrSubscription === 'subscription'
            ? async (data, actions) => {
              const response = await fetch('/api/paypal', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  planId: activeSubscription.paypalPlanId,
                  amount: (totalWithSubscription + shippingCost).toFixed(2),
                }),
              });
              const json = await response.json();
              return json.data.id;
            }
            : undefined
        }
        onSuccess={(details, data) => {
          const ppUser = {
            ...user,
            email: details.payer.email_address,
          };
          dispatch(updatePaypalInfo(ppUser, data));
          !checkout.upsell &&
            setTimeout(() => {
              a_upsellShown();
              dispatch(toggleModal({ modal: 'upsell', toggle: true }));
            }, 700);
          dispatch(simulateCheckout());
        }}
        onApprove={(data, actions) => {
          if (oneTimeOrSubscription === 'one-time') {
            actions.order.capture().then((details) => {
              const ppUser = {
                ...user,
                email: details.payer.email_address,
              };
              dispatch(updatePaypalInfo(ppUser, data));
              !checkout.upsell &&
                setTimeout(() => {
                  a_upsellShown();
                  dispatch(toggleModal({ modal: 'upsell', toggle: true }));
                }, 700);
              dispatch(simulateCheckout());
            });
          } else if (oneTimeOrSubscription === 'subscription') {
            const ppUser = {
              ...user,
            };
            dispatch(updatePaypalInfo(ppUser, data));
            !checkout.upsell &&
              setTimeout(() => {
                a_upsellShown();
                dispatch(toggleModal({ modal: 'upsell', toggle: true }));
              }, 700);
            dispatch(simulateCheckout());
          }
        }}
        onClick={() => {
          dispatch(paypalCheckout());
          a_payPalButtonPressed(app.remarketingUrl);
        }}
        onCancel={() => {
          dispatch(cancelPaypalCheckout());
        }}
        onError={(err) => console.log(err)}
      />
    </div>
  );
};

export default PayPalBtn;

export const NewPaypalButton = () => {
  const { paypalCheckout, cancelPaypalCheckout } = checkoutActions;
  const dispatch = useDispatch();
  const { app, user, checkout } = useSelector((state) => state);
  const { shippingCost, totalPrice, totalWithSubscription } = checkout;
  const { subscriptions } = app;

  const { oneTimeOrSubscription } = checkout;
  const [activeSubscription] = subscriptions.filter(
    (subscription) => subscription.isDefault,
  );

  return !user.shippingInfoError ? (
    <PayPalButtons
      forceReRender={[
        user,
        totalPrice,
        shippingCost,
        oneTimeOrSubscription,
        totalWithSubscription,
      ]}
      style={{
        height: 55,
        tagline: false,
        layout: 'horizontal',
      }}
      createOrder={
        oneTimeOrSubscription === 'one-time'
          ? (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: (totalPrice + shippingCost).toFixed(2),
                  },
                  description: 'Photo Boards',
                },
              ],
            });
          }
          : undefined
      }
      createSubscription={
        oneTimeOrSubscription === 'subscription'
          ? async (data, actions) => {
            const response = await fetch('/api/paypal', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                planId: activeSubscription.paypalPlanId,
                amount: (totalWithSubscription + shippingCost).toFixed(2),
              }),
            });
            const json = await response.json();
            return json.data.id;
          }
          : undefined
      }
      onSuccess={(details, data) => {
        const ppUser = {
          ...user,
          email: details.payer.email_address,
        };
        dispatch(updatePaypalInfo(ppUser, data));
        !checkout.upsell &&
          setTimeout(() => {
            a_upsellShown();
            dispatch(toggleModal({ modal: 'upsell', toggle: true }));
          }, 700);
        dispatch(simulateCheckout());
      }}
      onApprove={(data, actions) => {
        if (oneTimeOrSubscription === 'one-time') {
          dispatch(updateCheckout({ paypalApproving: true }));
          actions.order
            .capture()
            .then((details) => {
              const ppUser = {
                ...user,
                email: details.payer.email_address,
              };
              dispatch(updatePaypalInfo(ppUser, data));
              !checkout.upsell &&
                setTimeout(() => {
                  a_upsellShown();
                  dispatch(toggleModal({ modal: 'upsell', toggle: true }));
                }, 700);
              dispatch(simulateCheckout());
            })
            .finally(() => {
              dispatch(updateCheckout({ paypalApproving: false }));
            });
        } else if (oneTimeOrSubscription === 'subscription') {
          const ppUser = {
            ...user,
          };
          localStorage.setItem('used', 1);
          dispatch(updatePaypalInfo(ppUser, data));
          !checkout.upsell &&
            setTimeout(() => {
              a_upsellShown();
              dispatch(toggleModal({ modal: 'upsell', toggle: true }));
            }, 700);
          dispatch(simulateCheckout());
        }
      }}
      onClick={() => {
        dispatch(paypalCheckout());
        a_payPalButtonPressed(app.remarketingUrl);
      }}
      onCancel={() => {
        dispatch(cancelPaypalCheckout());
      }}
      onError={(err) => console.log(err)}
    />
  ) : (
    <div
      onClick={() => verifyAddress()}
      style={{
        height: '55px',
        display: 'flex',
        cursor: 'pointer',
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ffc439',
      }}
    >
      <PaypalButtonLogo style={{ width: '76px', height: '24px' }} />
    </div>
  );
};
